/** @jsx jsx */
import { jsx } from 'theme-ui'

export default props =>
  <div
    {...props}
    sx={{
      margin: '0 auto',
      maxWidth: 'container',
      pt: [2],
      pb: [2, 2],
      px: [2, 2, 3, 0]
    }}
  />